import { createFragmentContainer, graphql } from 'react-relay/legacy';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as tracking from 'dibs-tracking';
import { removeLocaleSegment } from 'dibs-intl/exports/urls';

import { Breadcrumbs } from 'dibs-elements/exports/Breadcrumbs';
import { FormattedMessage } from 'dibs-react-intl';

import { updateUriRef as updateUriRefAction } from '../../actions/filterActions';
import { getAppliedFilter } from '../SbSharedRefineMenu/sbSharedRefineMenuHelpers';

import {
    checkRewardedSeller,
    checkTradeUserUnderTopLevelLoyaltyTiers,
} from 'dibs-buyer-layout/exports/sellerBrandingHelpers';
import { isSellerBrandingRemovalTestVariant } from 'dibs-buyer-layout/exports/sellerBrandingRemovalAbTestHelpers';

// constants
import { pageTypeConstants as PAGE_TYPES } from '../../constants/pageTypeConstants';

// some breadcrumbs are not search / browse pages, but special landing pages instead :
const specialCaseLinks = [
    'creators',
    'collections',
    'designs',
    'seller-directory',
    'infogallery',
    'contemporary',
    // home page
    '',
];

const backToItemDefaultMessage = (
    <FormattedMessage id="SbSharedPageBreadcrumbs.backToItem" defaultMessage="< Back To Item" />
);

const testSpecialCase = path => {
    const segments = path.split('/').filter(Boolean);

    // check for /creators/{vertical}/ or /creators/{creator}/
    const isCreatorsSpecialCase = segments.length === 2 && segments[0] === 'creators';
    return specialCaseLinks.includes(path.replace(/\//g, '')) || isCreatorsSpecialCase;
};

const SbSharedPageBreadcrumbsComponent = ({ itemSearch, user, isClient, updateUriRef }) => {
    const { pageType, appliedFilters, seller } = itemSearch || {};
    const isMoreFromSeller = pageType === PAGE_TYPES.MORE_FROM_SELLER;
    const itemPk = getAppliedFilter(appliedFilters, 'itemPk')?.values?.[0]?.urlLabel;

    function fireTracking(event) {
        const breadcrumbs = itemSearch?.breadcrumbs;
        const lastBreadcrumb = breadcrumbs?.[breadcrumbs.length - 1];
        const currentCategoryName = lastBreadcrumb?.text;

        tracking.trackEvent(
            {
                category: 'navigation',
                action: 'breadcrumb click',
                label: `${currentCategoryName}|${event.target.text}`,
            },
            event
        );
    }

    function updateRoute(event) {
        const path = removeLocaleSegment(event.target.pathname);
        const shouldUpdateRoute =
            !testSpecialCase(path) &&
            // more from seller links directly back to PDP and shouldn't be
            // caught by SB router
            !isMoreFromSeller;

        if (shouldUpdateRoute) {
            event.preventDefault();
            fireTracking(event);
            if (updateUriRef) {
                updateUriRef({ uriRef: path });
            }
        }
    }

    let breadcrumbs = itemSearch?.breadcrumbs;

    if (isMoreFromSeller && itemPk) {
        breadcrumbs = [
            {
                text: backToItemDefaultMessage,
                path: `/id-${itemPk}`,
                isLinkable: true,
            },
        ];
    }

    if (pageType === PAGE_TYPES.DEALER) {
        breadcrumbs =
            isClient &&
            (!isSellerBrandingRemovalTestVariant() ||
                checkRewardedSeller(seller) ||
                checkTradeUserUnderTopLevelLoyaltyTiers(user || null))
                ? breadcrumbs
                : [];
    }

    return (
        <Breadcrumbs
            breadcrumbs={breadcrumbs}
            onClick={updateRoute}
            isLastItemActive={isMoreFromSeller}
        />
    );
};

SbSharedPageBreadcrumbsComponent.propTypes = {
    itemSearch: PropTypes.object,
    user: PropTypes.object,
    isClient: PropTypes.bool,
    updateUriRef: PropTypes.func,
};

const mapStateToProps = state => ({ isClient: state.relayVariables.variables.isClient });

export const SbSharedPageBreadcrumbs = createFragmentContainer(
    connect(mapStateToProps, { updateUriRef: updateUriRefAction })(
        SbSharedPageBreadcrumbsComponent
    ),
    {
        itemSearch: graphql`
            fragment SbSharedPageBreadcrumbs_itemSearch on ItemSearchQueryConnection {
                seller {
                    ...sellerBrandingHelpers_seller
                }
                pageType
                appliedFilters {
                    name
                    values {
                        urlLabel
                    }
                }
                breadcrumbs {
                    text
                    path
                    isLinkable
                }
            }
        `,
        user: graphql`
            fragment SbSharedPageBreadcrumbs_user on User {
                ...sellerBrandingHelpers_user
            }
        `,
    }
);
