const REGULAR_MODULE_TYPE = 'topItem';
const BANNER_MODULE_TYPE = 'topItemBanner';
const ICONIC_DESIGN_MODULE_TYPE = 'topItemIconicDesign';
const BANNER_TILES_MODULE_TYPE = 'topItemBannerTiles';

export {
    REGULAR_MODULE_TYPE,
    BANNER_MODULE_TYPE,
    ICONIC_DESIGN_MODULE_TYPE,
    BANNER_TILES_MODULE_TYPE,
};
