/**
 * @generated SignedSource<<059a824aabd12f2466531a3844da21b8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedTitle_itemSearch$data = {
  readonly appliedFilters: ReadonlyArray<{
    readonly name: string | null;
    readonly values: ReadonlyArray<{
      readonly urlLabel: string | null;
    } | null> | null;
  } | null> | null;
  readonly meta: {
    readonly header: string | null;
    readonly subHeader: string | null;
  } | null;
  readonly pageType: string | null;
  readonly searchCorrections: {
    readonly originalSearchTerm: string | null;
  } | null;
  readonly searchTerm: string | null;
  readonly seller: {
    readonly " $fragmentSpreads": FragmentRefs<"sellerBrandingHelpers_seller">;
  } | null;
  readonly soldItems: {
    readonly totalResults: number | null;
  } | null;
  readonly totalResults: number | null;
  readonly " $fragmentType": "SbSharedTitle_itemSearch";
};
export type SbSharedTitle_itemSearch$key = {
  readonly " $data"?: SbSharedTitle_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedTitle_itemSearch">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalResults",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedTitle_itemSearch",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pageType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "searchTerm",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchCorrectionsType",
      "kind": "LinkedField",
      "name": "searchCorrections",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "originalSearchTerm",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "MetaData",
      "kind": "LinkedField",
      "name": "meta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "header",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "subHeader",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SearchBrowseParsedParam",
      "kind": "LinkedField",
      "name": "appliedFilters",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SearchBrowseParsedParamValue",
          "kind": "LinkedField",
          "name": "values",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "urlLabel",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "soldItems",
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 28
        }
      ],
      "concreteType": "soldResultsConnection",
      "kind": "LinkedField",
      "name": "soldResults",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": "soldResults(first:28)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Seller",
      "kind": "LinkedField",
      "name": "seller",
      "plural": false,
      "selections": [
        {
          "kind": "InlineDataFragmentSpread",
          "name": "sellerBrandingHelpers_seller",
          "selections": [
            {
              "alias": "isRewarded",
              "args": [
                {
                  "kind": "Literal",
                  "name": "listNames",
                  "value": [
                    "SELLER_REWARDS_1",
                    "SELLER_REWARDS_2"
                  ]
                }
              ],
              "kind": "ScalarField",
              "name": "isAnchorListsMember",
              "storageKey": "isAnchorListsMember(listNames:[\"SELLER_REWARDS_1\",\"SELLER_REWARDS_2\"])"
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};
})();

(node as any).hash = "94b92af04c2173ae47236dea8716eded";

export default node;
