/**
 * @generated SignedSource<<906979a52ca92d4267b03d5117a46055>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbSharedHead_itemSearch$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly item: {
        readonly " $fragmentSpreads": FragmentRefs<"SharedHeadMetaTags_items">;
      } | null;
    } | null;
  } | null> | null;
  readonly meta: {
    readonly disableIndexing: boolean | null;
    readonly prev: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"SharedHeadLinkTags_metadata" | "SharedHeadMetaTags_metadata" | "SharedHeadScriptTags_metadata" | "SharedHeadTitleTag_metadata">;
  } | null;
  readonly pageType: string | null;
  readonly " $fragmentType": "SbSharedHead_itemSearch";
};
export type SbSharedHead_itemSearch$key = {
  readonly " $data"?: SbSharedHead_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbSharedHead_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbSharedHead_itemSearch",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "MetaData",
      "kind": "LinkedField",
      "name": "meta",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "prev",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "disableIndexing",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SharedHeadTitleTag_metadata"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SharedHeadMetaTags_metadata"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SharedHeadLinkTags_metadata"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SharedHeadScriptTags_metadata"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pageType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ItemSearchQueryEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ItemSearchResult",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Item",
              "kind": "LinkedField",
              "name": "item",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "SharedHeadMetaTags_items"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "421a29ac4bb8e7dba03903f2ed04a780";

export default node;
