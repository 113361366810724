import {
    SHOW_UNAVAILABLE_ITEM_MODAL,
    UNAVAILABLE_ITEM_NOTIFICATION_KEY,
} from '../finding/SbRespNotifications/sbRespNotificationConstants';

export function sbRespNotificationsReducer(state, action) {
    switch (action.type) {
        case SHOW_UNAVAILABLE_ITEM_MODAL:
            return {
                ...state,
                notifications: { [UNAVAILABLE_ITEM_NOTIFICATION_KEY]: true },
            };
        default:
            return state || { notifications: {} };
    }
}
