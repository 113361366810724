import PropTypes from 'prop-types';
import LazyLoad from 'react-lazy-load';

export function Visibility(props) {
    return (
        <LazyLoad onContentVisible={props.onContentVisible}>
            <span />
            {/*Lazy load component needs child element*/}
        </LazyLoad>
    );
}

Visibility.propTypes = {
    onContentVisible: PropTypes.func.isRequired,
};
