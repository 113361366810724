import PropTypes from 'prop-types';
import { createFragmentContainer, graphql } from 'react-relay/legacy';

import { SharedHeadTitleTag } from '../../shared/SharedHead/SharedHeadTitleTag';
import { SharedHeadMetaTags } from '../../shared/SharedHead/SharedHeadMetaTags';
import { SharedHeadLinkTags } from '../../shared/SharedHead/SharedHeadLinkTags';
import { SharedHeadScriptTags } from '../../shared/SharedHead/SharedHeadScriptTags';

function SbSharedHeadComponent({ itemSearch, imageFallback }) {
    const { meta: metadata } = itemSearch;
    const items = itemSearch?.edges || [];

    return (
        <>
            <SharedHeadTitleTag metadata={metadata} />
            <SharedHeadMetaTags
                metadata={metadata}
                items={items.map(edge => (edge ? edge.node.item : null))}
                imageFallback={imageFallback}
            />
            <SharedHeadLinkTags metadata={metadata} />
            <SharedHeadScriptTags metadata={metadata} />
        </>
    );
}

SbSharedHeadComponent.propTypes = {
    itemSearch: PropTypes.object.isRequired,
    imageFallback: PropTypes.string,
};

export const SbSharedHead = createFragmentContainer(SbSharedHeadComponent, {
    itemSearch: graphql`
        fragment SbSharedHead_itemSearch on ItemSearchQueryConnection {
            meta {
                prev
                disableIndexing
                ...SharedHeadTitleTag_metadata
                ...SharedHeadMetaTags_metadata
                ...SharedHeadLinkTags_metadata
                ...SharedHeadScriptTags_metadata
            }
            pageType
            edges {
                node {
                    item {
                        ...SharedHeadMetaTags_items
                    }
                }
            }
        }
    `,
});
