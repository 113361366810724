import { lazy, Suspense } from 'react';

const LazyComponent = lazy(() =>
    import(/* webpackChunkName: "SbRespFollowSearchBanner" */ './SbRespFollowSearchBanner')
);

export const SbRespFollowSearchBannerLazy = props => (
    <Suspense fallback="">
        <LazyComponent {...props} />
    </Suspense>
);
