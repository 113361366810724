export function getPublisherTagInstance() {
    // NOTE: stole this logic from .com
    // https://github.com/1stdibs/1stdibs.com/blob/master/dibs/assets/js/global/modules/ads.js#L81
    const gt = window && window.googletag;
    // FINDING-1427 The second condition prevents errors
    // from being thrown if the user has an ad blocker enabled
    if (!gt || (gt && !gt.apiReady)) {
        return null;
    }

    return gt;
}
