import * as tracking from 'dibs-tracking';

export const trackSortEvent = sortValue => {
    const sortValueMap = {
        newest: 'newest',
        'price-high': 'sort price high - low',
        'price-low': 'sort price low - high',
        relevance: 'relevance',
        featured: 'featured',
        popular: 'popular',
    };

    tracking.trackEvent({
        category: 'results page refinement',
        action: 'sort',
        label: sortValueMap[sortValue],
        value: 0,
        isInteractiveEvent: true,
    });
};
